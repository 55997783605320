@import 'erpcore/assets/scss/main';

.portal-sign-in {
  background: rgba(getColor(white), 1);
  padding: 84px 140px;
  width: 630px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin: 0px auto;
  margin-bottom: $spacing * 8;


  @include breakpoint($to: mobile) {
    width: 90%;
    padding: $spacing * 5 $spacing * 3;
    box-sizing: border-box;
    margin: 0px 5%;
    margin-bottom: $spacing * 4;
  }

  &__title {
    font: $font-3;
  }
}