@import 'erpcore/assets/scss/main';

.portal-footer {
    $this: &;
    position: relative;
    display: flex;
    max-width:1186px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px;
    color: rgba(getColor(grey), 1);
    font-size: 11px;
    line-height: 17px;
    margin-top: auto;
    border-top: 1px solid rgba(var(--midGrey), 1);

    @include breakpoint(tablet) {
        padding: ($spacing * 2) ($spacing * 3);
    }

    @include breakpoint(mobile) {
        font-size: 11px;
    }
}
