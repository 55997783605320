@import 'erpcore/assets/scss/main';

.portal-main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    max-width: 1190px;
    margin: 0px auto;
}
