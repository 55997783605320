@import 'erpcore/assets/scss/main';

.portal-front-main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;

    &__logo {
        width: 100%;
        max-width: 180px;

        &-wrapper {
            margin: 80px auto;
        }
    }
}
