@import 'erpcore/assets/scss/main';

.portal-2fa {
  background: rgba(getColor(white), 1);
  padding: 84px 140px;
  width: 630px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  margin: 0px auto;

  &__subtitle {
    position: relative;
    top: -40px;
    display: block;
    text-align: center;
    margin: 0px auto;
    padding: 0 $spacing * 2;
    font-size: 18px;
    max-width: 650px;
    font-weight: lighter;
    line-height: 1.5;

    .button {
      position: relative;
      top: -1px;
      font-size: 18px;
      font-weight: lighter;
    }
  }


  @include breakpoint($to: mobile) {
    width: 90%;
    padding: $spacing * 5 $spacing * 3;
    box-sizing: border-box;
    margin: 0px 5%;
  }

  &__title {
    font: $font-3;
  }
}