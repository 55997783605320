@import 'erpcore/assets/scss/main';

.mobile-menu {
    $_this: &;
    display: none;
    position: fixed;
    flex-wrap: wrap;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $spacing * 7 $spacing * 3 $spacing;
    overflow: auto;
    background: rgba(var(--darkGrey), 1);
    height: 100vh;

    .sidebar--show-deck & {
        display: block;
        display: flex;
    }

    @include breakpoint($from: mobile) {
        display: none !important;
    }

    &__project {
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;
        padding: 0 0 $spacing * 3;
        width: 100%;
        height: auto;
        text-align: center;
        list-style-type: none;
        border-bottom: 1px solid rgba(#fff, 0.2);
        color: #fff;

        &-logo {
            width: auto;
            max-width: 100px;
            margin: 0 0 12px;

            &-image {
                display: block;
                width: 100px;
                max-width: 100%;
                margin: 0 auto;
                height: auto;

            }
        }

        &-title {
            flex: 0 0 100%;
            margin: 0;
            font-size: 16px;
        }

        &-location {
            flex: 0 0 100%;
            margin: 4px 0 0;
            font-size: 14px;
            line-height: 1.4;
        }
    }

    &__main {
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;
        padding: $spacing * 2 0;
        width: 100%;
        height: auto;
        text-align: center;
        list-style-type: none;
        border-bottom: 1px solid rgba(#fff, 0.2);
    }

    &__aside {
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;
        padding: $spacing * 2 0;
        width: 100%;
        height: auto;
        text-align: center;
        list-style-type: none;
    }

    &__item {
        flex: 0 0 100%;
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 16px;
        line-height: 1;

        &-icon {
            width: 40px;
            position: relative;
            top: 42px;

            svg, path, line {
                fill: rgba(white, 1);
            }
        }

        #{$_this}__main & {
            text-transform: uppercase;
        }

        &-link {
            display: block;
            max-width: 300px;
            margin: 0 auto;
            padding: $spacing * 2 0;
            text-decoration: none !important;
            color: #fff;
            margin-left: 55px;

            &:hover {
                color: #fff;
            }

            &.active {
                color: rgba(#fff, 0.4);
                &:hover {
                    color: rgba(#fff, 0.4);
                }
            }
        }
    }

    &__logo {
        padding: $spacing $spacing * 2 $spacing * 3;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
    }

    .deals-select {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
    }

    .key-contacts {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        margin-top: 40px;
        width: 100%;
        &-item {
            color: rgba(var(--white), 1);
        }
    }
}
