@import 'erpcore/assets/scss/main';

.deals-select {
  position: absolute;
  top: 100px;
  width: 244px;
  left: 94px;
  right: 11px;
  background: rgba(var(--lightGrey), 1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
  border: 1px solid #ECECEC;
  border-radius: 5px;
  margin-right: $spacing * 2;
  margin-left: $spacing ;
  margin-top: $spacing * 2;
  z-index: 999;

  &--hover {

    & .deals-select__deal {
      cursor: pointer;
    }
    &:hover {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    }
  }

  &__loader {
    text-align: center;
    background: rgba(var(--white), 1);
  }

  &__deals {
    //  display: none;
    position: absolute;
    background: var(--white);
    width: 100%;
    max-height: 441px;
    overflow: auto;
    border: 1px solid #ECECEC;
    visibility: hidden;
    opacity: 0;
    transform: scale(.9);
    transition: all .2s ease-in-out;
    z-index: 99;
    border-radius: 0 0 5px 5px;

    .deals-select__deal {
      background: rgba(var(--white), 1);
    }

    &--opened {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(var(--white), 1);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(var(--midGrey), 1);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(var(--midGrey), 1);
    }
  }

  &__deal {
    display: block;
    position: relative;
    padding: $spacing * 2;
    padding-right: $spacing  * 5;
    border: none;
    border-bottom:  1px solid #ECECEC;
    background: rgba(var(--lightGrey), 1);
    outline: none;
    width: 100%;
    text-align: left;
    color: rgba(var(--darkGrey), 1);

    &-arrow-down,
    &-arrow-right{
      position: absolute;
      right: $spacing * 2;
      width: 12px;
      height: 12px;
    }

    &-arrow-down {
      top: $spacing * 2;
    }

    &-arrow-right {
      bottom: $spacing * 2;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:first-child {
      border-top: none;
    }

    &-info {
      display: flex;
      width: 50%;
      padding: 30px;
      flex-direction: column;

      @include breakpoint($to: mobile) {
        padding: $spacing * 2;

        br {
          display: block;
          margin: $spacing / 2;
          content: "";
        }
      }

      @include breakpoint($to: small) {
        width: 100%;
      }

      &-label {
        font: $font-label;
        margin-bottom: 0;
        font-weight: bold;
        color: rgba(var(--darkGrey), 1);
      }

      &-value {
        font: $font-label;
        margin-bottom: 0;

        &:last-of-type {
          margin-bottom: 0;
          margin-top: auto;
        }
      }

      &-location {
        font: $font-5;
        margin-bottom: $spacing;
        font-weight: lighter;
        text-transform: uppercase;
      }

      &-title {
        font: $font-3;
        margin-bottom: 12px;
        font-weight: bolder;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-bottom: 2px;

        @include breakpoint($to: mobile) {
          font-size: 20px;
        }
      }
    }
  }
}